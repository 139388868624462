<template>
  <div class="font-poppins flex flex-row items-center modern-color-theme" :class="computedClasses" data-component-name="VSValidation">
    <p class="text-sm leading-5 font-normal" v-text="props.message" />
  </div>
</template>
<script setup lang="ts">
import {computed} from "vue";

defineOptions({
  name: 'VSValidation'
})

type Type = 'warning' | 'error'

const props = defineProps<{
  type: Type,
  message: string
}>()

const computedClasses = computed(() => ({
  'text-yellow-500': props.type === 'warning',
  'text-red-500': props.type === 'error'
}))
</script>